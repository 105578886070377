import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import All from './components/All';
import Mint from './components/Mint';

function App() {
  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" element={<Layout />}>
    //       <Route index element={<All />} />
    //     </Route>
    //   </Routes>
    // </BrowserRouter>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Mint />}>
        <Route index element={<Mint />} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;

import { useEffect } from 'react';

const Mint = () => {
    useEffect(() => {
        // const script = document.createElement('script');
        // script.src = 'js/mint.js';
        // script.async = true;

        // document.body.appendChild(script);
        document.body.style.background = 'url("images/rainbow-clouds.png")';

        return () => {  
            // document.body.style.background = 'none';

            window.location.reload(false);
        }
    }, []);

    return (
        <div className="mint-container">
            <h1>YOMMSFRENS</h1>
            {/* <h3>Phase 3 Public Mint Live Now</h3> */}
            
            <img src="images/mint-characters.png" width="900" height="500" />

            <h2 id="connectionStatus">All minting phases have ended. Thanks for your participation and support!</h2>
            {/* <h2 id="connectedAccount"></h2>

            <div id="mintVisibility">
                <div>
                    <button id="minusBtn" className="minusBtn" type="button">-</button>
                    <span id="mintCount" className="mintCount"></span>
                    <button id="plusBtn" className="plusBtn" type="button">+</button>
                </div>

                <button id="mintBtn" className="mintBtn" type="button">MINT</button>
            </div> */}

            <img className="yf-logo" src="images/yf-logo.png" width="90" height="90" />
        </div>
    );
};

export default Mint;